import React, { Component } from "react";

import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import * as JsSearch from "js-search"

class ClientSearch extends Component {
  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    indexByTitle: false,
    indexByAuthor: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: "",
    selectedStrategy: "",
    selectedSanitizer: "",
    
  }
  /**
   * React lifecycle method that will inject the data into the state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine } = nextProps
      return {
        indexByTitle: engine.TitleIndex,
        indexByAuthor: engine.AuthorIndex,
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy,
      }
    }
    return null
  }
  async componentDidMount() {
    this.rebuildIndex()
    //this.setState({ searchQuery: 'ice away' });
    //setTimeout(this.setState({ searchQuery: 'ice away' }), 10000);
  }

  findTether = (e) => {
    if (e != null) {
      if(e.master_tether_selector != 'tether') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
    /*if(e.master_tether_selector != 'tether') {
        return true;
      } else {
        return false;
      }*/
  }
  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTitle,
      indexByAuthor,
    } = this.state
    const { items } = this.props
    const dataToSearch = new JsSearch.Search("id")
    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    }
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("title"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())
    // sets the index attribute for the data
    dataToSearch.addIndex(['acf','brand_name'])
    dataToSearch.addIndex(['acf','brand_copy'])
    dataToSearch.addIndex(['acf','product_copy'])
    dataToSearch.addIndex('name')
    items.map((page) =>(
        <React.Fragment>
            {page.acf !=null &&
                <React.Fragment>
                    {page.acf.product_points_rp != null && page.acf.product_points_rp.map((points,index) =>(
                        <React.Fragment>
                            {dataToSearch.addIndex(['acf','product_points_rp',index,'product_point'])}
                        </React.Fragment>
                    ))
                    }
                    {page.acf.brand_points_rp != null && page.acf.brand_points_rp.map((bpoints,index) =>(
                        <React.Fragment>
                            {dataToSearch.addIndex(['acf','brand_points_rp',index,'brand_point'])}
                        </React.Fragment>
                    ))
                    }
                    {page.parent_element != null && 
                        <React.Fragment>
                            {dataToSearch.addIndex(['parent_element','title'])}
                            {dataToSearch.addIndex(['parent_element','path'])}
                            {page.parent_element.acf !=null &&
                                <React.Fragment>
                                    {dataToSearch.addIndex(['parent_element','acf','brand_name'])}
                                    {dataToSearch.addIndex(['parent_element','acf','brand_copy'])}
                                </React.Fragment>
                            }
                            {page.parent_element.parent_element != null && 
                                <React.Fragment>
                                    {dataToSearch.addIndex(['parent_element','parent_element','title'])}
                                </React.Fragment>
                            } 
                        </React.Fragment>
                    }
                    {page.acf.prod_add_collateral != null && page.acf.prod_add_collateral.map((collateral,index) =>(
                        <React.Fragment>
                            {dataToSearch.addIndex(['acf','prod_add_collateral',index,'prod_collateral_name'])}
                            {dataToSearch.addIndex(['acf','prod_add_collateral',index,'prod_collateral_name_line'])}
                            {dataToSearch.addIndex(['acf','prod_add_collateral',index,'prod_upload_collateral','name'])}
                        </React.Fragment>
                    ))
                    }
                    {page.acf.prod_add_data_sheets != null && page.acf.prod_add_data_sheets.map((datasheet,index) =>(
                        <React.Fragment>
                            {dataToSearch.addIndex(['acf','prod_add_data_sheets',index,'prod_collateral_name'])}
                            {dataToSearch.addIndex(['acf','prod_add_data_sheets',index,'prod_collateral_name_line'])}
                            {dataToSearch.addIndex(['acf','prod_add_data_sheets',index,'prod_collateral_name_line_3'])}
                            {dataToSearch.addIndex(['acf','prod_add_data_sheets',index,'prod_upload_data_sheet','name'])}
                        </React.Fragment>
                    ))
                    }
                </React.Fragment>
            }
        </React.Fragment>
    ))
    
    dataToSearch.addIndex(['acf','product_points_rp','number','product_point'])


    dataToSearch.addIndex(['acf','product_points_fp'])
    dataToSearch.addIndex(['parent_element','title'])
    dataToSearch.addIndex(['parent_element','acf','brand_name'])
    dataToSearch.addIndex(['parent_element','acf','brand_copy'])
    
    if (indexByTitle) {
      //dataToSearch.addIndex("title")
    }
    // sets the index attribute for the data
    if (indexByAuthor) {
      //dataToSearch.addIndex(["acf","brand_name"])
    }
    dataToSearch.addDocuments(items) // adds the data to be searched
    this.setState({ search: dataToSearch, isLoading: false })
  }
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state
    const queryResult = search.search(e.target.value)
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }
  handleSubmit = e => {
    e.preventDefault()
  }
  render() {
    const { searchResults, searchQuery } = this.state
    const { items } = this.props
    const queryResults = searchQuery === "" ? items : searchResults
    return (
      <section className="searchResults">
        <div>
        <div style={{ margin: "0 auto" }}>
          <form onSubmit={this.handleSubmit}>
            <div style={{ margin: "0 auto" }}>
              {/*
              <label htmlFor="Search" style={{ paddingRight: "10px" }}>
                Enter your search here
              </label>
                <input
                id="Search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder="Enter your search here"
              />
              */}

              <InputBase
                className="searchField"
                placeholder="Enter your search here..."
                value={searchQuery}
                onChange={this.searchData}
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton type="submit" className="iconButton" aria-label="search">
                <SearchIcon />
              </IconButton>

            
            </div>
          </form>
          <div className="searchResultsLIST">
            There are {queryResults.length} Results for {searchQuery}     
            <ul>
                {queryResults.sort(function(a, b){
                    if(a.title < b.title) { return -1; }
                    if(a.title > b.title) { return 1; }
                    return 0;
                }).sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                }).filter(page => this.findTether(page.acf)).map(item => {
                  return (
                    
                    <React.Fragment>
                    <li id={`row_${item.id}`} key={`row_${item.id}`}>
                        {item.acf !=null &&
                            <React.Fragment>
                                {item.acf.type_of_page != null &&
                                    <React.Fragment>
                                        {item.acf.type_of_page === 'cat' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: 'Category -  '+item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'product' &&
                                            <a href={item.parent_element.path+'products/'+item.slug}><span dangerouslySetInnerHTML={{ __html: item.parent_element.acf.brand_name+' - '+item.acf.product_name}}/></a>
                                        }
                                        {item.acf.type_of_page === 'brand' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: 'Brand - '+item.acf.brand_name}}/></a>
                                        }
                                        {item.acf.type_of_page === 'country' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: 'COUNTRY - '+item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'allbrand' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'port' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'cmp' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'dft' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'srch' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: item.title}}/></a>
                                        }
                                        {item.acf.type_of_page === 'apprsr' &&
                                            <a href={item.path}><span dangerouslySetInnerHTML={{ __html: item.title}}/></a>
                                        }
                                        
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {item.acf == null &&
                            <a href={item.publicURL} download={item.name}><span dangerouslySetInnerHTML={{ __html: 'PDF - '+item.name}}/></a>
                        }
                        
                        
                    </li>
                    </React.Fragment>
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
      </section>
    )
  }
}
export default ClientSearch